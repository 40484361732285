import React, {useState} from 'react'
import { Row, Col } from 'antd'

import CarouselFiveItems from "../../components/CarouselFiveItems/CarouselFiveItems"

import chevronRed from '../../assets/images/ic_chevron_right_24px_red.svg'

import {  
  feelSafeData,
  // feelSafeData2,
  // feelSafeData3,
  // feelSafeData4,
  // feelSafeData5,
  // feelSafeData6
} from '../../templates/AboutUs/constants'

import "./RHRSafety.scss"

const RHRSafety = () => {

  const [data] = useState(feelSafeData.slice(1));
  
  return (
    <div className="rhr-safety-container">
      <Row className="ant-row-center paddingpX50">
          <div className="rhr-safety-details">
            <div className="safety-title">
              {/* <img src={happyToCare} alt="Happy To Care" className="img-responsive" /> */}
              <h2 className="title-border text-right">Feel Safe at RHR</h2>
            </div>
            <div className="safety-details">
              <div>
                <p>With us, you come first. Your health and safety are our top priority. That is why we elevated our cleanliness protocols to make sure you are well taken care of.</p>
                <a className="know-more" href="/about-us#feel-safe-at-rhr" target="_blank">View More Stories <img src={chevronRed} alt="arrow right" /></a>
              </div>
            </div>
          </div>
        <Col span={24}>
          <CarouselFiveItems className="rhr-safety" data={data}/>
        </Col>
      </Row>
    </div>
  )
}

export default RHRSafety
