import React, { useEffect, useState } from 'react'
import { Row, Col, Carousel } from 'antd'

import { STATUS } from '../../enum'

import htc1 from '../../assets/images/happytocare/htc1.png'
import chevronRed from '../../assets/images/ic_chevron_right_24px_red.svg'
import imageFeature from '../../assets/images/Image-feature.png'

const CarouselFiveItems = ({ className, data }) => {

  const [feelSafeData, setFeelSafeData] = useState([]);
  useEffect(() => {
    if (className === "rhr-safety"){
      const result =  data.reduce((resultArray, item, index) => { 

        const chunkIndex = Math.floor(index/5)
        if(!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
        }
        resultArray[chunkIndex].push(item)
        return resultArray
      }, []);
       setFeelSafeData(result)

    } else {
      const result =  data.filter((updates) => updates.status === STATUS.PUBLISHED).reduce((resultArray, item, index) => { 

        const chunkIndex = Math.floor(index/5)
        if(!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
         }
         resultArray[chunkIndex].push(item)
         return resultArray
       }, []);
       setFeelSafeData(result)
       
    }
  }, [data])
  
  const trunc = (desc) => {
    const elipsis = desc.replace(/<\/?[^>]+(>|$)/g, "").split(" ");
    
    if(className === "updates-announcements"){
      if(elipsis.length > 26){
        return elipsis.filter((elip, key) => key <= 25).join(" ") + '...';
      }
    }
    
    if(className === "rhr-safety"){
      if(elipsis.length > 20){
        return elipsis.filter((elip, key) => key <= 19).join(" ") + '...';
      }
    }
    
    return desc;
  }

  const [isMobileView, setIsMobileView] = useState<boolean>(false)
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 812)
    }
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  })

  return (
    <div>
      { isMobileView ?
        <div>
          {feelSafeData?.map((feelSafeCard, index) => (
            <Carousel
              draggable={true}
              arrows
              className={`${className}-carousel rhr-carousel`}
              key={index}
              dots={false}
            >
              {Object.values(feelSafeCard)?.map((feelSafe) => (
                <div className="rhr-carousel-card" key={index}>
                  <Row className="carousel-upper-row">
                    <Col 
                      className="carousel-card-container rhr-carousel-card_1" 
                      key={index}
                      style={{ 
                        padding: "0",
                      }}
                    >
                    <img src={feelSafe?.photo_url} alt="Happy To Care" className="img-responsive"/>
                    <div 
                      className="rhr-carousel-details"
                      style={{ 
                        width: "100%",
                        left: "0",
                      }}
                    >
                      <article className="article">
                        <h3 dangerouslySetInnerHTML={{ __html: feelSafe?.title}}></h3>
                        <div dangerouslySetInnerHTML={{ __html: trunc(feelSafe?.short_description)}} style={{marginBottom: "15px"}}></div>
                        {feelSafe?.redirect_link !== "" && (
                          <a className="know-more" href={feelSafe?.redirect_link} target="_blank">Know More <img src={chevronRed} alt="arrow right" /></a>
                        )} 
                      </article>
                    </div>
                    </Col>
                  </Row>
                </div>
              ))}
            </Carousel>
          ))}
        </div>
      : 
        <Carousel draggable={true} className={`${className}-carousel rhr-carousel`}>
          {feelSafeData?.map((feelSafeCard, index) => (
            <div className="rhr-carousel-card" key={index}>
              <Row className="carousel-upper-row">
                {Object.values(feelSafeCard).slice(0,2).map((feelSafe, index) => (
                  <Col 
                    className="carousel-card-container rhr-carousel-card_1" 
                    key={index}
                    style={{ 
                      padding: "0",
                    }}
                  >
                  <img src={feelSafe?.photo_url} alt="Happy To Care" className="img-responsive"/>
                  <div 
                    className="rhr-carousel-details"
                    style={{ 
                      width: "100%",
                      left: "0",
                    }}
                  >
                    <article className="article">
                      <h3 dangerouslySetInnerHTML={{ __html: feelSafe?.title}}></h3>
                      <div dangerouslySetInnerHTML={{ __html: trunc(feelSafe?.short_description)}} style={{marginBottom: "15px"}}></div>
                      {feelSafe?.redirect_link !== "" && (
                        <a className="know-more" href={feelSafe?.redirect_link} target="_blank">Know More <img src={chevronRed} alt="arrow right" /></a>
                      )} 
                    </article>
                  </div>
                  </Col>
                ))}
              </Row>

              <Row 
                className="carousel-lower-row"
                style={{
                  justifyContent: Object.values(feelSafeCard)?.slice(2).length < 3 ? "flex-start" : "space-between",
                }}
              >
                {Object.values(feelSafeCard)?.slice(2).map((feelSafe) => (
                  <div 
                    className={Object.values(feelSafeCard)?.slice(2).length < 3 ? "carousel-card-container rhr-carousel-card_2 less-than-three-carousel" : "carousel-card-container rhr-carousel-card_2"}
                  >
                    <img src={feelSafe?.photo_url} alt="Happy To Care" className="img-responsive"/>
                    <div 
                      className={className === "rhr-safety" ? "rhr-carousel-details safety-details-card_2" : "rhr-carousel-details"}
                      style={{ 
                        width: "100%",
                        left: "0",
                      }}
                    >
                      <article className= {className === "rhr-safety" ? "article-card_2" : ""}>
                        <h3 dangerouslySetInnerHTML={{ __html: feelSafe?.title}}></h3>
                        <div dangerouslySetInnerHTML={{ __html: trunc(feelSafe?.short_description)}} style={{marginBottom: "15px"}}></div>
                        {feelSafe?.redirect_link !== "" && (
                          <a className="know-more" href={feelSafe?.redirect_link} target="_blank">Know More <img src={chevronRed} alt="arrow right" /></a>
                        )} 
                      </article>
                    </div>
                  </div>
                ))}
              </Row>
            </div>
          ))}
        </Carousel>
      }
    </div>
  )
}

export default CarouselFiveItems

