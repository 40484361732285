import React, { useState, useEffect } from "react"
import { Row, Col, Carousel, Input } from "antd"

import searchIcon from "../../assets/images/ic_search_24px.svg"
import meetingImage from "../../assets/images/meeting.png"
import brandLogo from "../../assets/images/brands/go_logo.png"
import CrownePlazaManila from "../../assets/images/searchmeetingplace/CrownePlazaManila.jpg"
import GrandSummitHotel from "../../assets/images/searchmeetingplace/GrandSummitHotel.png"
import SummitHotelTacloban from "../../assets/images/searchmeetingplace/SummitHotelTacloban.jpg"
import SummitGalleriaCebu from "../../assets/images/searchmeetingplace/SummitGalleriaCebu.jpg"
import DusitThaniMactanCebu from "../../assets/images/searchmeetingplace/DusitThaniMactanCebu.jpg"

import { HotelI } from "../../interface"

import "./SearchMeetingPlace.scss"

const { Search } = Input

const SearchMeetingPlace = ({ data }) => {
  const [search, setSearch] = useState("")
  const [result, setResults] = useState<HotelI[]>([])
  const [searchResult, setSearchResult] = useState<boolean>(false)
  const [noResult, setNoResult] = useState<boolean>(false)

  useEffect(() => {

    data.tarsiergraphql.getHotels.sort((a, b) => a.name.localeCompare(b.name))
    setResults(data.tarsiergraphql.getHotels)
  
  })

  const onSearch = (val: string, e) => {
    if (typeof window !== "undefined") {
      Array.from(document.querySelectorAll(".search-hotel-wrap")).forEach(el =>
        el.classList.remove("active")
      )
    }
    if (val) {
      setTimeout(() => {
        const res = result
          .filter(
            res =>
              (res.name !== "" &&
                res.name
                  .toLocaleLowerCase()
                  .includes(val.toLocaleLowerCase())) ||
              (res.address !== "" &&
                res.address
                  .toLocaleLowerCase()
                  .includes(val.toLocaleLowerCase()))
          )
          .map((v, k) => {
            return v
          })

        if (res.length === 0) {
          setSearch(val.toLocaleLowerCase())
          setSearchResult(false)
          setNoResult(true)
          return
        }

        if (res.length !== 0) {
          setSearch(val.toLocaleLowerCase())
          setResults(result)
          setNoResult(false)
          setSearchResult(true)
          return
        }
      }, 600)
    }

    if (val === "") {
      setSearch("")
      setSearchResult(false)
      setNoResult(false)
      return
    }
  }

  const getHotelsDetails = (id: string) => {
    if (typeof window !== "undefined") {
      const ele = document.getElementById("search-result-" + id)

      if (ele?.classList.contains("active")) {
        ele?.classList.remove("active")
        return
      }
      setSearchResult(false)
      ele?.classList.add("active")
      return
    }
  }

  const closeResult = () => {
    setNoResult(false)
    setSearchResult(false)
  }

  const onErrorImg = e => {
    e.target.src = brandLogo
  }

  return (
    <div className="search-meeting-container">
      <Row>
        <Col xl={12}  xs={27} className="search-meeting-details-wrap">
          <div className="search-meeting-details">
            <article>
              <h5 className="title">Meetings & Celebrations</h5>
              <h2 className="title-border">
                Design Your Event Experiences with us!
              </h2>
              <p>
                Whether it's for business or to turn celebrations into once in a
                lifetime memories, find the perfect venue from our diverse
                properties with spectacular views and amenities.
              </p>
            </article>
            <div className="search-meeting-form">
              <span className="title">Search Hotels & Locations</span>
              <Search
                placeholder="e.g Summit, Go Hotels, Tacloban, Cebu"
                allowClear
                enterButton={<img src={searchIcon} alt="Search" />}
                size="large"
                onSearch={onSearch}
              />
            </div>
          </div>
        </Col>
        <Col xs={30} xl={12}>
          <div
            className="search-hotel-wrap no-result-hotels"
            style={noResult ? {} : { display: "none" }}
          >
            <span
              className="close-search-result rhr-close-button"
              onClick={closeResult}
            ></span>
            <h3>Search Results</h3>
            <span>
              Ooops! No results {search === "" ? "found." : `for "${search}"`}
            </span>
          </div>
          <div
            className="search-hotel-wrap search-result"
            style={searchResult ? {} : { display: "none" }}
          >
            <span
              className="close-search-result rhr-close-button"
              onClick={closeResult}
            ></span>
            <h3>Search Results</h3>
            <ul>
              {result
                .filter(
                  res =>
                    (res.name !== "" &&
                      res.name
                        .toLocaleLowerCase()
                        .includes(search.toLocaleLowerCase())) ||
                    (res.address !== "" &&
                      res.address
                        .toLocaleLowerCase()
                        .includes(search.toLocaleLowerCase()))
                )
                .map((res, key) => (
                  <li key={key} onClick={() => getHotelsDetails(res.id)}>{res.name}</li>
                ))}
            </ul>
          </div>
          {result
            .filter(res => res.name !== "")
            .map((res, key) => (
              <div
                key={key} 
                id={`search-result-${res.id}`}
                className={`search-hotel-wrap specific-item`}
              >
                <span
                  className="close-search-result rhr-close-button"
                  onClick={() => getHotelsDetails(res.id)}
                ></span>
                <img src={res.website_logo} onError={onErrorImg} alt={res.name} />
                <h3>{res.name}</h3>
                <span>ADDRESS: {res.address}</span>
                <Row>
                  <Col span={12} style={{ textAlign: "left" }}>
                    <a href={res.website_link} className="rhr-button" target="_blank">
                      KNOW MORE
                    </a>
                  </Col>
                  <Col span={12}>
                    <a
                      href={res.request_for_proposal_link}
                      target="_blank"
                      className="request-hotel-proposal"
                    >
                      Request for proposal
                    </a>
                  </Col>
                </Row>
              </div>
            ))}
          ;
          <Carousel className="meeting-carousel carousel-dots-custom">
            <div className="meeting-wrap">
              <div className="meeting-image">
                <img
                  src={CrownePlazaManila}
                  className="img-responsive"
                  alt="Meeting"
                />
              </div>
              <div className="meeting-image-details">
                <article>
                  <p>Crowne Plaza Manila Galleria</p>
                  <span>Ballroom</span>
                  <span>Ortigas Center</span>
                </article>
              </div>
            </div>
            <div className="meeting-wrap">
              <div className="meeting-image">
                <img
                  src={GrandSummitHotel}
                  className="img-responsive"
                  alt="Meeting"
                />
              </div>
              <div className="meeting-image-details">
                <article>
                  <p>Grand Summit Hotel General Santos</p>
                  <span>Grand Summit Ballroom</span>
                  <span>General Santos</span>
                </article>
              </div>
            </div>
            <div className="meeting-wrap">
              <div className="meeting-image">
                <img
                  src={SummitHotelTacloban}
                  className="img-responsive"
                  alt="Meeting"
                />
              </div>
              <div className="meeting-image-details">
                <article>
                  <p>Summit Hotel Tacloban</p>
                  <span>Grand Ballroom</span>
                  <span>Tacloban</span>
                </article>
              </div>
            </div>
            <div className="meeting-wrap">
              <div className="meeting-image">
                <img
                  src={SummitGalleriaCebu}
                  className="img-responsive"
                  alt="Meeting"
                />
              </div>
              <div className="meeting-image-details">
                <article>
                  <p>Summit Galleria Cebu</p>
                  <span>Meeting Room</span>
                  <span>Cebu</span>
                </article>
              </div>
            </div>
            <div className="meeting-wrap">
              <div className="meeting-image">
                <img
                  src={DusitThaniMactanCebu}
                  className="img-responsive"
                  alt="Meeting"
                />
              </div>
              <div className="meeting-image-details">
                <article>
                  <p>Dusit Thani Mactan Cebu</p>
                  <span>Ballroom</span>
                  <span>Cebu</span>
                </article>
              </div>
            </div>
          </Carousel>
        </Col>
      </Row>
    </div>
  )
}

export default SearchMeetingPlace
