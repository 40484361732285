import React from 'react'
import { Row, Col, Carousel } from 'antd'

import KalanggamanIslandLeyte from '../../assets/images/KalanggamanIslandLeyte.png'

import "./FeaturedContents.scss"


const FeaturedContents = () => {
  return (
    <div className="featured-content-container">
      <Row className="paddingpX50">
        <Col xl={12} xs={23} className="image-featured">
          <Carousel autoplay autoplaySpeed={5000} className="image-featured-carousel carousel-dots-custom">
           <img src={KalanggamanIslandLeyte} className="img-responsive" alt="Image Feature" />
          </Carousel>
        </Col>
        <Col xl={12} xs={21} className="featured-content-details">
          <div>
            <h2 className="title-border">The booking information you need, all in just one Hotel Guide.</h2>
            <div className="description">
              <p>Amidst these trying times, we at Robinsons Hotels and Resorts, have found an opportunity to fulfill our mission to provide world-class hospitality for everyone - from brave front liners to business travelers, staycationers, and more! </p>
              <p>In our hotel guide, you will find the guidelines per hotel classification, ongoing offers, cleanliness standards, and hotel details for easier reference.</p>
              <p>No matter what your purpose of staying, you have a home with us. <br />Find the perfect hotel with this handy RHR hotel guide!</p>
            </div>
            <a href="https://uqrmecdn.s3.us-east-2.amazonaws.com/u/415861/809958-1545704050067c9236a58bcfde59219333a3227.pdf" target="_blank" className="rhr-button">KNOW MORE</a>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default FeaturedContents
