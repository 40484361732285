import BannerImage from "../../assets/images/hero-banner-image.png"
import WhoweareImageJGSummit from "../../assets/images/aboutus/JGSummit.jpg"
import AboutusHeroBanner from "../../assets/images/aboutus/About_US_Hero_Banner.png"
import RLC from "../../assets/images/aboutus/RLC.png"
import AboutusRHR from "../../assets/images/aboutus/AboutUs-RHR.jpg"
import DTMC from "../../assets/images/aboutus/DTMC.png"
import ANR from "../../assets/images/aboutus/ANR.jpg"
import NustarFiliBrandPortfolio from "../../assets/images/aboutus/Nustar&FiliBrandPortfolio.png"
import CrowneBrandPortfolio from "../../assets/images/aboutus/Crowne-BrandPortfolio.png"
import HolidayInnBrandPortfolio from "../../assets/images/aboutus/HolidayInnBrandPortfolio.png"
import GrandSummitBrandPortfolio from "../../assets/images/aboutus/GrandSummitBrandPortfolio.png"
import SHRBrandPortfolio from "../../assets/images/aboutus/SHRBrandPortfolio.png"
import GOHBrandPortfolio from "../../assets/images/aboutus/GOHBrandPortfolio.jpg"
import WestinBrandPortfolio from "../../assets/images/aboutus/WestinBrandPortfolio.png"
import COC from "../../assets/images/aboutus/COC.jpg"
import IHGPromise from "../../assets/images/aboutus/IHGPromise.jpg"
import DTMC2 from "../../assets/images/aboutus/DTMC2.jpg"
import SafeTravels from "../../assets/images/aboutus/SafeTravels.jpg"
import DOTSafetySeal from "../../assets/images/aboutus/DOTSafetySeal.png"
import BrandPromisedOPBP from "../../assets/images/aboutus/IMG_8334.jpg"
import Mission from "../../assets/images/aboutus/Mission.jpg"
import Vision from "../../assets/images/aboutus/Vision.png"

// Who We Are Dummy Data
export const whoWeAreData1 = [
  {
    header_title: "<p>Who We Are</p>",
    text_content: "",
    link: "",
    pdf_url: "",
  },
]

export const whoWeAreData3 = [
  {
    photo_thumbnail_url: RLC,
    header_title: "<br>Robinsons Land Corporation</p>",
    text_content:
      "<p>Robinsons Land Corporation (RLC), one of the Philippines’ leading real estate companies, is involved in the development and operation of shopping malls and hotels. It is also one of the country’s most reputable developers of mixed-use properties, office buildings, residential condominiums, as well as land and residential housing developments, including socialized housing projects located in key cities and other urban areas nationwide.</p>",
    link: "https://www.robinsonsland.com/",
    pdf_url: "",
  },
]

export const whoWeAreData2 = [
  {
    photo_thumbnail_url: WhoweareImageJGSummit,
    header_title: "<p>JG Summit Holdings, Inc.</p>",
    text_content:
      "<p>JG Summit Holdings, Inc. is one of the leading conglomerates in the Philippines, with business interests in Food and Beverages, Real Estate and Hotels, Air Transportation, Banking and Petrochemicals, as well as core investments in Real Estate, Telecommunications and in Power Distribution.</p>",
    link: "https://www.jgsummit.com.ph/",
    pdf_url: "",
  },
]

export const whoWeAreData4 = [
  {
    photo_thumbnail_url: AboutusRHR,
    header_title: "<p>Robinsons Hotels and Resorts</p>",
    text_content: `<p>Robinsons Hotels and Resorts (RHR) is the hospitality arm of Robinsons Land Corporation (RLC) and is on the path to becoming the Philippines’ biggest and best hotel group with the widest variety of formats and brands and most diverse geographic reach.</p>
    <p>In the international deluxe category, it has the IHG managed Crowne Plaza Galleria and Holiday Inn Galleria, Dusit Thani Mactan Cebu, and the upcoming The Westin Sonata Place Ortigas. For its homegrown brands, there are the Summit Hotels and Resorts brand and the essential service hotel chain Go Hotels located in the metro and in key cities nationwide. Its latest development is the ambitious NUSTAR Resort & Casino, poised to become Cebu’s first integrated resort development. NUSTAR Resort & Casino will be home to three exceptional hotels: NUSTAR Hotel Cebu (the only ultra-luxury hotel outside of Metro Manila), Fili (a five-star resort offering genuine Filipino hospitality), and Grand Summit (a lifestyle celebrations hotel).</p>
    <p>Set on an island already renowned for its hospitality, each hotel has been designed to redefine 5-star luxury in accommodations, dining, and leisure.</p>`,
    link: "",
    pdf_url: "",
  },
]

// Brand Promise Dummy Data
export const brandPromiseData1 = [
  {
    header_title: "<p>Brand Promise</p>",
    text_content: "",
    link: "",
    pdf_url: "",
  },
]

export const brandPromiseData2 = [
  {
    photo_thumbnail_url: Vision,
    header_title:
      "<p>VISION <br><br> To be a world-class, Filipino hotel brand.</p>",
    text_content:
      "<p>Be the biggest and best hospitality company in the Philippines appealing to guests, colleagues, community and stakeholders.</p>",
    link: "",
    pdf_url: "",
  },
]

export const brandPromiseData3 = [
  {
    header_title: "<p>To create great hotel experiences.</p>",
    text_content:
      "<p>Provide true Filipino hospitality across all touch points by creating engaging guest experiences.</p>",
    link: "",
    pdf_url: "",
  },
]

export const brandPromiseData4 = [
  {
    photo_thumbnail_url: Mission,
    header_title:
      "<p> MISSION <br> <br> To create great hotel experiences.</p>",
    text_content:
      "<p>Provide true Filipino hospitality across all touch points by creating engaging guest experiences.</p>",
    link: "",
    pdf_url: "",
  },
]

// Brand Portfolio Dummy Data
export const brandPortfolioData1 = [
  {
    header_title: "<p>Brand Portfolio</p>",
    text_content:
      "<p>Whether for business or leisure, we have a diverse portfolio of International and Homegrown brands to choose from. Robinsons Hotels and Resorts strives to create genuine and heartfelt Filipino service culture to every kind of traveler.</p>",
    link: "",
    pdf_url: "",
  },
]

export const brandPortfolioData2 = [
  {
    photo_thumbnail_url: DTMC,
    header_title: "<p>Dusit Thani Mactan Cebu Resort</p>",
    text_content:
      "<p>A vibrant and inviting retreat blends Filipino warmth and Thai hospitality for a heavenly haven by the ocean at our hotel Mactan Cebu, Philippines resort.</p><p>On northern tip of the Punta Engaño Peninsula, Dusit Thani Mactan Cebu is easily accessible from the Mactan-Cebu International Airport by land and sea transport. Surrounded by tropical islands, natural, cultural and historic treasures are all within reach.</p><p>Guests relax in stylish rooms and suites, with gracious service, innovative cuisine, luxurious amenities and stunning views all on hand.</p>",
    link: "https://www.dusit.com/dusitthani-mactancebu/?utm_source=RHRWeb&utm_medium=KnowMore&utm_campaign=DTMC",
    pdf_url: "",
  },
]

export const brandPortfolioData3 = [
  {
    photo_thumbnail_url: CrowneBrandPortfolio,
    header_title: "<p>Crowne Plaza Manila Galleria</p>",
    text_content:
      "<p>Located at Ortigas Center,  only a few minutes away from different multinational corporations and government offices, Crowne Plaza Manila Galleria rooms provide a generous living space, designed to make sure that you are well-rested, energized, accomplished, and ready for a productive day. Crowne Plaza Manila Galleria boasts of an exclusive Club Lounge with signature cocktails and comfortable breathing space featuring a magnificent panoramic view of the city. The hotel also offers flexible event spaces with an approximate total of 34,000 sqm in size, ideal for small to large scale gatherings of up to 1500 guests in its grand pillarless ballroom.</p>",
    link: "https://www.ihg.com/crowneplaza/hotels/gb/en/quezon-city/mnlcp/hoteldetail?utm_source=RHRWeb&utm_medium=KnowMore&utm_campaign=Crowne",
    pdf_url: "",
  },
]

export const brandPortfolioData4 = [
  {
    photo_thumbnail_url: HolidayInnBrandPortfolio,
    header_title: "<p>Holiday Inn Galleria</p>",
    text_content:
      "<p>Nestling in the heart of Ortigas Business District, Holiday Inn Manila Galleria is an ideal leisure destination for travelers seeking to experience the energetic life in Metro Manila.</p><p>It features spacious and well-appointed guestrooms and suites, an all day dining restaurant, a vibrant bar that features live bands and DJs, kids’ activity area, and various intimate meeting spaces. The rooms provide premium amenities such as high speed Wi-Fi internet, 32in LCD TV, mini bar, and complimentary coffee and tea.</p>",
    link: "https://www.ihg.com/holidayinn/hotels/us/en/pasig-city/mnlgl/hoteldetail?utm_source=RHRWeb&utm_medium=KnowMore&utm_campaign=HolidayInn",
    pdf_url: "",
  },
]

export const brandPortfolioData5 = [
  {
    photo_thumbnail_url: GrandSummitBrandPortfolio,
    header_title: "<p>Grand Summit Hotel</p>",
    text_content:
      "<p>Grand Summit is the first upscale hotel of Robinsons Hotels and Resorts and the newest lifestyle destination situated right at the heart of General Santos City. It recently opened its doors to the public last October 15, 2021.</p><p>An opulent setting for work, play, and celebrations – it aims to be remembered not as a venue, but as a heartening feeling and delightful memory.</p>",
    link: "https://grandsummithotels.ph/?utm_source=RHRWeb&utm_medium=KnowMore&utm_campaign=GS",
    pdf_url: "",
  },
]

export const brandPortfolioData6 = [
  {
    photo_thumbnail_url: SHRBrandPortfolio,
    header_title: "<p>Summit Hotels & Resorts</p>",
    text_content:
      "<p>Summit Hotels and Resorts are midscale business and leisure hotels that uplift your spirits at the end of a long day. As its name connotes, Summit Hotels elevates the guest experience to new heights with its personable brand of service, energetic vibe, and spirited environment - promising Enriching Moments with every stay. Whether business or leisure, short trips or extended stays, in the hearts of cities or on the road, Summit Hotels and Resorts has a property for every traveler.</p><p>Come unwind with the hotel's smart and green in-room amenities, vibrant cafe, refreshing swimming pool, rejuvenating spa, and well-equipped fitness center. Flexible meetings and events spaces and a 24/7 workstation make business on the move more productive. All these, at an unbeatable value! </p><p>At Summit Hotels and Resorts, we do whatever it takes to make our guests feel valued and cared for. That's why we invite you to relax with your four-legged family member at select properties. Pet accommodations policy may vary per location.</p>",
    link: "https://www.summithotels.ph/?utm_source=RHRWeb&utm_medium=KnowMore&utm_campaign=SHR",
    pdf_url: "",
  },
]

export const brandPortfolioData7 = [
  {
    photo_thumbnail_url: GOHBrandPortfolio,
    header_title: "<p>Go Hotels</p>",
    text_content: `<p>Fresh, friendly and youthful, Go Hotels (GOH) is the Philippines' largest chain in the budget hotel sector (as of December 15, 2020), with franchises and corporate-owned properties, recognized as the first mover for creating the innovative "book early, pay less", value essential segment. </p><p>This award-winning Filipino hotel chain opened its first hotel in Mandaluyong City on April 4, 2010, and to date, operates 17 hotels nationwide. In the year 2020, Go Hotels reached a milestone with its 10th anniversary of consistently providing travelers with clean and stress-free accommodations, essential amenities, safety and security, all at affordable rates!</p>`,
    link: "https://www.gohotels.ph/?utm_source=RHRWeb&utm_medium=KnowMore&utm_campaign=GOH",
    pdf_url: "",
  },
]

export const brandPortfolioData8 = [
  {
    photo_thumbnail_url: NustarFiliBrandPortfolio,
    header_title: "<p>NUSTAR Resort & Casino</p>",
    text_content: `<p>NUSTAR Resort and Casino is a world-class integrated resort development in Cebu City envisioned to be the region's ultimate leisure and entertainment destination.</p><p>It will elevate and redefine luxury in gaming, entertainment, leisure, dining and retail in the entire Central and Southern Philippines.  It shall feature more than a thousand keys of well-appointed hotel rooms varying from upscale to ultra-luxury accommodations. RHR will open the first hotel in May 2022 with Fili, an elegant and genuinely Filipino hotel concept offering five-star service. The vision for Fili brand is to expand regionally and be renowned for its joyful and warm Filipino hospitality. The second hotel that will open in 2023, NUSTAR Hotel, promises to be the only ultra-luxury hotel outside of Manila designed for the VIP clientele of NUSTAR Casino.  The third hotel will be the finest among the Grand Summit chain of hotels in the Philippines, a contemporary lifestyle accommodation for work (MICE and corporate), play (leisure) and celebrations (socials).</p>`,
    link: "",
    pdf_url: "",
  },
]

export const brandPortfolioData9 = [
  {
    photo_thumbnail_url: WestinBrandPortfolio,
    header_title: "<p>The Westin Manila Sonata Place</p>",
    text_content: `<p>The Westin Manila Sonata Place boasts its prime location for business and leisure travelers, in the Ortigas Business District in Manila, just 13km from Ninoy Aquino International Airport. </p><p>This multi-story hotel promises heavenly experiences, from its guest rooms, amenities, fitness studio, bar and restaurant, and event spaces.</p><p>The hotel is slated to open in 2022.</p>`,
    link: "https://www.marriott.com/hotels/travel/mnlwp-the-westin-manila-sonata-place/?utm_source=RHRWeb&utm_medium=KnowMore&utm_campaign=Westin",
    pdf_url: "",
  },
]

// Feel Safe at RHR Dummy Data
export const feelSafeData = [
  {
    title: "<p>Feel Safe at RHR</p>",
    short_description: `<p>The tourism industry in the Philippines faced many challenges during the pandemic. Amidst these trying times, RHR has found an opportunity to fulfill our mission to provide world-class hospitality for everyone - from brave front liners to business travelers, staycationers, and more! No matter what your purpose of staying, you have a home with us at RHR.</p>
    <p>At RHR, you come first. Your health and safety are our top priority. That is why we elevated our cleanliness protocols to make sure you are well taken care of.</p>`,
    redirect_link: "",
    pdf_url: "",
  },
  {
    photo_url: COC,
    title: "<p>CIRCLE OF CLEAN</p>",
    short_description:
      "<p>All Go Hotels and Summit Hotels and Resorts establishments follow the Circle of Clean (COC) protocol, which is modeled after the World Health Organization’s hygiene and cleanliness standards and the Department of Health New Normal protocol. </p><p>Aside from common precautions seen in almost every establishment today, the COC has extra safety measures including a seamless online check-in, luggage disinfection upon check-in and checkout, COC ambassadors stationed in common areas ready to assist, rigorous and effective food handling hygiene, associates training, COCO alerts—a unique in-house SMS notification system that reminds guests to observe proper handwashing hygiene and more.</p><p>All abovementioned is part of RHR's Happy to CARE initiative - the hotel group's service culture and multi-faceted goal to showcase its unceasing commitment to CARE - Creating A Remarkable Experience for customers, colleagues, community, and company.</p>",
    redirect_link:
      "https://www.summithotels.ph/hotel-updates/robinsons-hotels-and-resorts-circle-clean-protocols?utm_source=RHR+Web&utm_medium=KnowMore&utm_campaign=COC&utm_content=Feel+Safehttps://www.gohotels.ph/",
    pdf_url: "",
  },
  {
    photo_url: IHGPromise,
    title: "<p>IHG'S WAY OF CLEAN</p>",
    short_description:
      "<p>The IHG Way of Clean includes cleanliness information in hotels and on IHG Hotels & Resorts’ booking channels, social distancing operating procedures, guidance on the use of protective equipment as necessary by hotel colleagues, updated colleague training and certification, sanitizer stations at high-touch points throughout hotels. We also have the IHG Clean Promise—guests are reassured that: Good isn’t good enough–we’re committed to high levels of cleanliness. That means clean, well maintained, clutter-free rooms that meet our standards. If this isn’t what you find when you check-in then we promise to make it right.</p>",
    redirect_link:
      "https://www.ihg.com/content/us/en/customer-care/clean-promise?cm_mmc=EXTERNAL-_-6C-_-GLO-_-EN-_-AWA-_-CleanPromise-_-VANITY",
    pdf_url: "",
  },
  {
    photo_url: DTMC2,
    title: "<p>DUSIT CARE - STAY WITH CONFIDENCE</p>",
    short_description:
      "<p>As the world continues to deal with the ongoing coronavirus situation, we would like to assure you that we have taken all measures necessary to ensure your safety and well-being at each of our distinctive properties. On top of this, we have carefully crafted the Dusit Care – Stay with Confidence programme, featuring five new services designed to bring you the peace of mind you deserve, while enriching your stays with new memorable experiences and extra convenience and value. The warm, caring and gracious hospitality you have come to know and trust remains at the heart of everything we do.</p>",
    redirect_link: "https://www.dusit.com/covid-19/",
    pdf_url: "",
  },
  {
    photo_url: SafeTravels,
    title: "<p>WORLD TRAVEL & TOURISM COUNCIL - SAFETRAVELS STAMP</p>",
    short_description:
      "<p>The World Travel & Tourism Council's SafeTravels Stamp was created for travelers to recognize destinations and businesses around the world which have adopted the SafeTravels health and hygiene global standardized protocols.</p><p>With the health and hygiene protocols implemented in our properties consistent and aligned with the global standard, you can just stay with ease.</p>",
    redirect_link:
      "https://wttc.org/COVID-19/SafeTravels-Global-Protocols-Stamp",
    pdf_url: "",
  },
  {
    photo_url: DOTSafetySeal,
    title: "<p>DEPARTMENT OF TOURISM - SAFETY SEAL</p>",
    short_description:
      "<p>The Safety Seal is a seal issued by government agencies, affirming that an establishment has been found compliant with the minimum public health standards set by the government, including the adoption or integration of the StaySafe.ph in contact tracing applications.</p><p>We continue to demonstrate our commitment to the health of tourists and locals alike with the Safety Seals we have acquired in our properties.</p>",
    redirect_link: "https://safetyseal.dti.gov.ph/",
    pdf_url: "",
  },
]

export const bannerImages = [
  {
    url: AboutusHeroBanner,
  },
]

export const hashValue = [
  {
    hash: "#who-we-are",
    id: "1",
  },
  {
    hash: "#brand-promise",
    id: "2",
  },
  {
    hash: "#brand-portfolio",
    id: "3",
  },
  {
    hash: "#feel-safe-at-rhr",
    id: "4",
  },
]
