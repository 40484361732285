import React, { useEffect, useState } from "react"

import CarouselFiveItems from "../../components/CarouselFiveItems/CarouselFiveItems"

import "./UpdatesAndAnnouncements.scss"

const UpdatesAndAnnouncements = ({data}) => {
  const [updatesAndAnnouncements, setUpdatesAndAnnouncements] = useState([]);

  useEffect(() => {
    setUpdatesAndAnnouncements(data.tarsiergraphql.getUpdatesAndAnnouncements);
  }, [updatesAndAnnouncements]);
  
  return (
    <div className="updates-container">
      <span>Updates &</span>
      <h2 className="title-border">Announcements</h2>
      <CarouselFiveItems className="updates-announcements" data={updatesAndAnnouncements} />
    </div>
  )
}

export default UpdatesAndAnnouncements
